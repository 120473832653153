import React from "react";
import Stage from "../../components/info-site/Stage";
import Layout from "../../layouts/en";
import Seo from "../../components/info-site/Seo";
import GetStarted from "../../components/info-site/GetStarted";

import StageContent from "../../components/info-site/StageContent";

import BrandPartnerships from "../../components/info-site/BrandPartnerships";
import cpaMainFeatures from "../../data/cpaMainFeatures";
import SideContentWithImg from "../../components/info-site/SideContentWithImg";
import SimpleSideContent from "../../components/info-site/SimpleSideContent";
import LogoWithTitle from "../../components/styleguide/LogoWithTitle";

const AcquireNewCustomers = props => {
    return (
        <Layout location={props.location}>
            <Seo titleId="cpaTitle" descriptionId="cpaDescription" />
            <Stage
              title="cpaTitle"
              icon
              withoutMedia
            >
              <StageContent
                title="cpaSideTitle"
                content="cpaSideDescription"
                ctaLink="https://rtbmedia.hubspotpagebuilder.com/book-a-demo"
                ctaText="homepageStagePrimaryCta"
              />
            </Stage>
            
            <BrandPartnerships title="cpaFeatures">
              {
                cpaMainFeatures.map(({id, ...rest}) => (
                  <SideContentWithImg
                    lightParagraph
                    key={id}
                    {...rest}
                  />
                ))
              }
            </BrandPartnerships>

            <SimpleSideContent
              title="cpaSimpleSideTitle"
              subtitle="cpaSimpleSideSubtitle"
              content="cpaSimpleSideContent"
              footer="cpaSimpleSideFooter"
              info={[
                {
                  id: "cpa-simple-side-info-1",
                  title: "cpaSimpleSideInfoTitleOne",
                  description: "cpaSimpleSideInfoDescOne",
                },
                {
                  id: "cpa-simple-side-info-2",
                  title: "cpaSimpleSideInfoTitleTwo",
                  description: "cpaSimpleSideInfoDescTwo",
                },
                {
                  id: "cpa-simple-side-info-3",
                  title: "cpaSimpleSideInfoTitleThree",
                  description: "cpaSimpleSideInfoDescThree",
                },
                {
                  id: "cpa-simple-side-info-4",
                  title: "cpaSimpleSideInfoTitleFour",
                  description: "cpaSimpleSideInfoDescFour",
                },
                {
                  id: "cpa-simple-side-info-15",
                  title: "cpaSimpleSideInfoTitleFive",
                  description: "cpaSimpleSideInfoDescFive",
                },
              ]}
            />

            <LogoWithTitle
              title="cpaBrandToBrand"
            />
            <LogoWithTitle
              title="cpaSoWhat"
              reversed
            />

            <GetStarted />
        </Layout>
    );
};

export default AcquireNewCustomers;