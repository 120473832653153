import React from 'react'
import { FormattedMessage } from 'react-intl'

import SimpleLogo from "../../assets/simple-logo.png"

import styles from './LogoWithTitle.module.css'

const LogoWithTitle = ({ title, reversed }) => {
  return (
    <div className={`${styles.LogoWithTitle} ${reversed ? styles.LogoWithTitle__reversed: ''}`}>
      <img src={SimpleLogo} alt='' />
      <h2><FormattedMessage id={title} /></h2>
    </div>
  )
}

export default LogoWithTitle