import React from 'react'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'


import TextInfo from '../styleguide/TextInfo'


import styles from '../../styles/info-site/SimpleSideContent.module.css'

const SimpleSideContent = ({
  title,
  subtitle,
  content,
  footer,
  info,
  centered
}) => {
  return (
    <div className={styles.SimpleSideContent}>
      <div className={styles.SimpleSideContentContainer}>
        <h2><FormattedMessage id={title} /></h2>
        <h3><FormattedMessage id={subtitle} /></h3>
        <p className={styles.SimpleSideContentDescription}><FormattedHTMLMessage id={content} /></p>
        <p className={styles.SimpleSideContentFooter}><FormattedHTMLMessage id={footer} /></p>
      </div>
      <div className={styles.SimpleSideContentInfoWrapper}>
        {
          info && info.length > 0 && info.map(({title, description}, idx) => (
            <TextInfo key={`cpa-side--${idx}`} className={styles.SimpleSideContentInfo} title={title} description={description} />
          ))
        }
      </div>
    </div>
  )
}

export default SimpleSideContent