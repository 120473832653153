import CpaImgOne from '../assets/cpa-1.png'
import CpaImgTwo from '../assets/cpa-2.png'
import CpaImgthree from '../assets/cpa-3.png'

const cpaMainFeatures = [
  {
    id: "cpa-features-1",
    title: "cpaFeaturesTitleOne",
    content: "cpaFeaturesDescOne",
    img: CpaImgOne,
  },
  {
    id: "cpa-features-2",
    title: "cpaFeaturesTitleTwo",
    content: "cpaFeaturesDescTwo",
    img: CpaImgTwo,
    reversed: true
  },
  {
    id: "cpa-features-3",
    title: "cpaFeaturesTitleThree",
    content: "cpaFeaturesDescThree",
    img: CpaImgthree,
  },
]

export default cpaMainFeatures;
